import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as employeeService from "./../../../services/EmployeeService";
import * as branchService from "./../../../services/BranchService";
import * as roomService from "./../../../services/RoomService";
import * as trainingTypeService from "./../../../services/TrainingTypeService";
import * as groupService from "./../../../services/GroupService";
import * as periodTypeService from "./../../../services/PeriodTypeService";
import * as groupMemberService from "./../../../services/GroupMemberService";
import NotFound from "./../../../components/notfound/Index";
import * as Alerts from "./../../../utils/Alerts";
import * as contractService from "./../../../services/ContractService";

import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useTokenData } from "../../../helper/useTokenData";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";
import GroupMemberAddModal from "./../../groupMember/modal/AddModal";
import * as date from "./../../../utils/DateNow";
import CommitButton from "./../../../components/commitButton/Index";
import { useStudent } from "../../../helper/useStudent";

export default function Index() {
  const { state, setState } = useMainContext();

  const [isView, setIsView] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [contractTrainers, setContractTrainers] = useState([]);
  const [group, setGroup] = useState({ startDate: date.Now(), groupMembers: [] });
  const [periodTypes, setPeriodTypes] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [selectedGroupMember, setSelectedGroupMember] = useState(-1);
  const [activeTab, setActiveTab] = useState("general");
  const [trainerOrAssistant, setTrainerOrAssistant] = useState("");
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [isOpenGroupMemberAddModal, setIsOpenGroupMemberAddModal] = useState(false);
  const [groupPlans, setGroupPlans] = useState([
    { label: "1-ci gün", weekDay: 1, checked: false, startTime: null, endTime: null },
    { label: "2-ci gün", weekDay: 2, checked: false, startTime: null, endTime: null },
    { label: "3-cü gün", weekDay: 3, checked: false, startTime: null, endTime: null },
    { label: "4-cü gün", weekDay: 4, checked: false, startTime: null, endTime: null },
    { label: "5-ci gün", weekDay: 5, checked: false, startTime: null, endTime: null },
    { label: "6-cı gün", weekDay: 6, checked: false, startTime: null, endTime: null },
    { label: "7-ci gün", weekDay: 7, checked: false, startTime: null, endTime: null },
  ]);

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();
  const location = useLocation();
  const navigate = useNavigate();
  const { idGroup } = useParams();

  useEffect(() => {
    getEmployees();
    getTrainingTypes();
    getBranchs();
    getPeriodTypes();
    idGroup && getGroupPlans();
    idGroup && getGroup();
    idGroup && getGroupMembers();

    if (location.pathname.includes("/view/")) {
      setIsView(true);
    }
  }, []);

  useEffect(() => {
    getRooms();
  }, [localStorage.branch]);

  const getGroupMembers = async () => {
    let result = await groupMemberService.getGroupMembersByGroup(1, 1000, idGroup);
    if (result.success) {
      setGroupMembers(result.data.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const handleGroupMemberAddModal = () => {
    setIsOpenGroupMemberAddModal(!isOpenGroupMemberAddModal);
  };

  const deleteGroupMember = () => {
    setGroup({ ...group, groupMembers: group.groupMembers.filter((x) => x.student?.idStudent != selectedGroupMember) });
  };

  const selectEmployee = (item) => {
    if (item != null) {
      if (trainerOrAssistant == "trainer") {
        setGroup({ ...group, fkIdTrainer: item?.rowId });
        getContractTrainers(item?.rowId);
      } else {
        setGroup({ ...group, fkIdAssistant: item?.rowId });
      }
    }
  };

  const addedGroupMember = (item) => {
    setGroup({ ...group, groupMembers: [...group?.groupMembers, item] });
  };

  const getGroup = async () => {
    let result = await groupService.getGroup(idGroup);
    if (result.success) {
      setGroup(result.data);
      getContractTrainers(result?.data?.fkIdTrainer);
    } else {
      Alerts.error(result.message);
    }
  };

  const getPeriodTypes = async () => {
    let result = await periodTypeService.getPeriodTypes();
    if (result.success) {
      setPeriodTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroupPlans = async () => {
    let result = await groupService.getGroupPlans(idGroup);
    if (result.success) {
      setGroupPlans(result?.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getRooms = async () => {
    let result = await roomService.getForSelect(localStorage.branch);
    if (result.success) {
      setRooms(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getBranchs = async () => {
    let result = await branchService.getBranchsByOrganizationForSelect(tokenData.organizationId);
    if (result.success) {
      setBranchs(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTrainingTypes = async () => {
    let result = await trainingTypeService.getTrainingTypes();
    if (result.success) {
      setTrainingTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getEmployees = async () => {
    let result = await employeeService.getForSelect();
    if (result.success) {
      setEmployees(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getContractTrainers = async (trainerId) => {
    let result = await contractService.getContractTrainerForSelect(trainerId);
    if (result.success) {
      setContractTrainers(result?.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateGroup = async () => {
    let item = groupPlans.filter((x) => x.checked == true);
    let groupMembers = group.groupMembers;
    let result = idGroup ? await groupService.updateGroup({ group: { ...group, fkIdBranch: localStorage.branch }, groupPlans: item, groupMembers }) : await groupService.addGroup({ group: { ...group, fkIdBranch: localStorage.branch }, groupPlans: item, groupMembers });
    if (result?.success) {
      Alerts.successWithBack(result.message, `${alias}/group`, navigate);
    } else {
      Alerts.error(result?.message);
    }
  };

  const handleInput = (e) => {
    setGroup({ ...group, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setGroup({ ...group, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idGroup ? (isView ? "Qrup məlumatları" : "Qrup məlumatlarının redaktəsi") : "Yeni qrup"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ MƏLUMATLAR
                    </a>
                  </li>
                  <li className={activeTab == "payment" ? "active" : ""} onClick={() => setActiveTab("payment")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      TƏLİMÇİ MƏLUMATLARI
                    </a>
                  </li>
                  <li className={activeTab == "plan" ? "active" : ""} onClick={() => setActiveTab("plan")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      QRUP PLANI
                    </a>
                  </li>
                  <li className={activeTab == "groupMember" ? "active" : ""} onClick={() => setActiveTab("groupMember")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      QRUP ÜZVLƏRİ
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      <FormInput style={"col-sm-6"} label={"Qrup adı"} name={"groupName"} required={true} value={group?.groupName} onChange={handleInput} disabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Təlim növü"} required={true} selectedValue={group?.fkIdTrainingType} changeSelectHandler={(e) => handleSelect(e, "fkIdTrainingType")} options={trainingTypes.map((trainingType) => ({ label: trainingType?.description, value: trainingType?.idTrainingType }))} isDisabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-4"} label={"Müddətin növü"} selectedValue={group?.fkIdTrainingPeriodType} changeSelectHandler={(e) => handleSelect(e, "fkIdTrainingPeriodType")} options={periodTypes.map((periodType) => ({ label: periodType?.description, value: periodType?.idPeriodType }))} isDisabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Müddət"} name={"period"} type={"number"} value={group?.period} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Tutum"} name={"capacity"} value={group?.capacity} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Başlama tarixi"} name={"startDate"} required={true} type={"date"} value={group?.startDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Bitmə tarixi"} name={"expirationDate"} type={"date"} value={group?.expirationDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-4"} label={"Otaq"} selectedValue={group?.fkIdRoom} changeSelectHandler={(e) => handleSelect(e, "fkIdRoom")} options={rooms.map((room) => ({ label: room?.roomNumber, value: room?.idRoom }))} isDisabled={isView} />
                      <FormInput style={"col-sm-12"} label={"Ətraflı"} name={"description"} value={group?.description} onChange={handleInput} disabled={isView} />
                      <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={group?.isActive ? true : false} onChange={() => setGroup({ ...group, isActive: group?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "payment" ? "active" : "")}>
                    <div className="row">
                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Təlimçi"} selectedValue={group?.fkIdTrainer} changeSelectHandler={(e) => handleSelect(e, "fkIdTrainer")} options={employees.map((employee) => ({ label: `${employee?.surname} ${employee?.name} ${employee?.middleName}`, value: employee?.idEmployee }))} isDisabled={isView} />
                      <div className="form-group col-sm-6">
                        <label className="label-control w-100"></label>
                        <button
                          className="btn btn-info"
                          disabled={isView}
                          onClick={() => {
                            setTrainerOrAssistant("trainer");
                            handleEmployeeSelectModal();
                          }}
                        >
                          Təlimçi seç
                        </button>
                      </div>
                      <Select placeholder={"Seç"} style={"col-sm-8"} label={"Təlimçi müqaviləsi"} selectedValue={group?.fkIdContractTrainer} changeSelectHandler={(e) => handleSelect(e, "fkIdContractTrainer")} options={contractTrainers.map((item) => ({ label: item?.contractNo, value: item?.idContractTrainer }))} isDisabled={isView} />

                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Assistent"} selectedValue={group?.fkIdAssistant} changeSelectHandler={(e) => handleSelect(e, "fkIdAssistant")} options={employees.map((employee) => ({ label: `${employee?.surname} ${employee?.name} ${employee?.middleName}`, value: employee?.idEmployee }))} isDisabled={isView} />
                      <div className="form-group col-sm-6">
                        <label className="label-control w-100"></label>
                        <button
                          className="btn btn-info"
                          disabled={isView}
                          onClick={() => {
                            setTrainerOrAssistant("assitant");
                            handleEmployeeSelectModal();
                          }}
                        >
                          Assistent seç
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "plan" ? "active" : "")}>
                    <div className="row">
                      <table className="table">
                        <thead>
                          <th></th>
                          <th>Həftənin günü</th>
                          <th>Başlama tarixi</th>
                          <th>Bitmə tarixi</th>
                        </thead>
                        <tbody>
                          {groupPlans.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <div className="checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={item.checked}
                                      onChange={(e) => {
                                        item.checked = !item.checked;
                                        setGroupPlans([...groupPlans]);
                                      }}
                                      disabled={isView}
                                    />
                                    <span className="checkbox-material">
                                      <span className="check" />
                                    </span>
                                  </label>
                                </div>
                              </td>
                              <td style={{ maxWidth: 50 }}>{item.label}</td>
                              <td className="td-actions text-left">
                                <input
                                  type={"time"}
                                  
                                  style={{ border: "none" }}
                                  disabled={!item.checked || isView}
                                  value={item?.startTime}
                                  onChange={(e) => {
                                    item.startTime = e.target.value;
                                    setGroupPlans([...groupPlans]);
                                  }}
                                />
                              </td>
                              <td className="td-actions text-left">
                                <input
                                  type={"time"}
                                  style={{ border: "none" }}
                                  disabled={!item.checked || isView}
                                  value={item?.endTime}
                                  onChange={(e) => {
                                    item.endTime = e.target.value;
                                    setGroupPlans([...groupPlans]);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "groupMember" ? "active" : "")}>
                    <div className="row">
                      {!isView && (
                        <div className="col-xs-12">
                          <button className="btn btn-info f-left" onClick={handleGroupMemberAddModal}>
                            Yeni qrup üzvü əlavə et
                          </button>
                          <button className="btn btn-danger f-left" onClick={deleteGroupMember} disabled={selectedGroupMember != -1 ? false : true}>
                            Sil
                          </button>
                        </div>
                      )}
                      <div className="col-xs-12">
                        <div className="card-content">
                          {group?.groupMembers?.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className="text-center">#</th>
                                    <th>Tələbə</th>
                                    <th>Başlama tarixi</th>
                                    {/* <th>Filial</th> */}
                                    {/* <th>Ödəniş</th>
                                    <th>Endirim</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {group.groupMembers.map((item, i) => (
                                    <tr key={item?.student?.idStudent} onClick={() => setSelectedGroupMember(selectedGroupMember == -1 ? item?.student?.idStudent : -1)} className={selectedGroupMember === item?.student?.idStudent ? "selectedRow" : ""}>
                                      <td className="text-center">{item?.idGroupMember ? i + 1 : "-"}</td>
                                      <td>{item?.student?.fullName}</td>
                                      <td>{item?.startDate?.split("T")[0]}</td>
                                      {/* <td>{item?.branch?.branchName}</td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <NotFound />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateGroup} />}
                  <Link to={`${alias}${id}/group`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={selectEmployee} /> : null}
      {isOpenGroupMemberAddModal ? <GroupMemberAddModal isOpen={isOpenGroupMemberAddModal} handleModal={handleGroupMemberAddModal} setAddedGroupMember={addedGroupMember} /> : null}
    </div>
  );
}
