import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../contexts/MainContext";
import { useOrganization } from "../../helper/useOrganization";
import { useEffect } from "react";
import { URL } from "./../../services/core/Urls";

export default function Index({ deletePath, editPath, addPath, exportPath, selectedRow, deleteRow, assignmentDetailModal, isOpenAssignmentDetail, changePasswordModal, isChangePassword }) {
  const { state, setState } = useMainContext();
  const alias = useOrganization();
  const isShowEdit = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && editPath.toLowerCase() == obj.menuName.toLowerCase());
  const isShowAdd = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && addPath.toLowerCase() == obj.menuName.toLowerCase());
  const isShowDelete = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && deletePath.toLowerCase() == obj.menuName.toLowerCase());
  const isShowExport = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && exportPath?.toLowerCase() == obj.menuName.toLowerCase());

  return (
    <>
      {isShowDelete && (
        <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteRow(selectedRow)}>
          Sil
        </button>
      )}
      {assignmentDetailModal && (
        <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right" onClick={() => assignmentDetailModal(!isOpenAssignmentDetail)}>
          Tapşırığın icra vəziyyəti
        </button>
      )}
      {changePasswordModal && (
        <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right" onClick={() => changePasswordModal(!isChangePassword)}>
          Şifrəni dəyiş
        </button>
      )}
      {isShowEdit && (
        <Link to={`${alias}/${editPath}/${selectedRow}`}>
          <button className="btn btn-info f-right" disabled={selectedRow == 0 ? true : false}>
            Redaktə et
          </button>
        </Link>
      )}
      {isShowAdd && (
        <Link to={`${alias}/${addPath}`}>
          <button className="btn btn-info f-right">Əlavə et</button>
        </Link>
      )}
      {isShowExport && (
        <button className="btn btn-success f-right" onClick={() => (window.location = URL.BaseURL + `/${exportPath}/${localStorage.branch}`)}>
          <i className="material-icons">download</i>
          Export
        </button>
      )}
    </>
  );
}
