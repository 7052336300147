import React, { useState, useEffect } from "react";

import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import Select from "./../../../components/select/Index";
import { useOrganization } from "../../../helper/useOrganization";

export default function ViewModal({ isOpen, handleViewModal, group }) {
  return (
    <div
      className={isOpen ? "modal m-block" : "modal"}
      id="viewGroupModal"
      role="dialog"
      aria-labelledby="viewGroupModal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5
              className="modal-title"
              style={{ fontWeight: "600" }}
              id="viewGroupModalLabel"
            >
              Qrupa baxış
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <form method="#" action="#">
                  <div className="row">
                    <FormInput
                      style={"col-sm-4"}
                      label={"Qrup adı"}
                      name={"groupName"}
                      value={group?.groupName}
                      disabled={true}
                    />
                    <FormInput
                      style={"col-sm-4"}
                      label={"Müddət"}
                      name={"period"}
                      value={group?.period}
                      disabled={true}
                    />
                    <FormInput
                      style={"col-sm-4"}
                      label={"Tutum"}
                      name={"capacity"}
                      value={group?.capacity}
                      disabled={true}
                    />
                    <FormInput
                      style={"col-sm-4"}
                      label={"Ətraflı"}
                      name={"description"}
                      value={group?.description}
                      disabled={true}
                    />
                    <FormInput
                      style={"col-sm-4"}
                      label={"Başlama tarixi"}
                      name={"startDate"}
                      value={group?.startDate?.split('T')[0]}
                      disabled={true}
                    />
                    <FormInput
                      style={"col-sm-4"}
                      label={"Bitmə tarixi"}
                      name={"expirationDate"}
                      value={group?.expirationDate?.split('T')[0]}
                      disabled={true}
                    />

                    <FormCheckbox
                      label={"Aktivlik"}
                      style={"col-sm-12"}
                      name={"isActive"}
                      checked={group?.isActive == 1 ? true : false}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-fill btn-danger"
              onClick={handleViewModal}
            >
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
