import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as userService from "../../services/UserService";
import * as helperService from "../../services/HelperService";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { useTokenData } from "../../helper/useTokenData";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Box } from "@mui/material";
//import Checkbox from "@material-ui/core/Checkbox";
import CommitButton from "../../components/commitButton/Index";
import * as branchService from "../../services/BranchService";

export default function Index() {
  const { state, setState } = useMainContext();
  const [branchs, setBranchs] = useState([]);
  const [selectedNodeId, setSelectedNodeId] = useState();
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();
  const { idUser, idOrganization } = useParams();
  const navigate = useNavigate();
  const tokenData = useTokenData();

  useEffect(() => {
    getBranchs();
  }, []);

  const addOrUpdate = async () => {
    if (idUser) {
      let request = { fkIdUserRelated: idUser, fkIdBranchs: [...new Set(selectedNodes)], idOrganization:idOrganization };
      let result = await userService.addUserBranchPrivilage(request);
      if (result?.success) {
        Alerts.successWithBack(result.message, `${alias}/user`, navigate);
      } else {
        Alerts.error(result.message);
      }
    } else Alerts.error("!");
  };

  const getBranchs = async () => {
    setLoading(true);
    let idOrg = tokenData.isSysUser == "1" ? idOrganization : tokenData?.organizationId;
    let result = await branchService.getBranchsByOrganizationForSelect(idOrg);
    if (result.success) {
      setBranchs(result.data);
      getUserBranchPrivilages();
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const getUserBranchPrivilages = async () => {
    let result = await userService.getUserBranchPrivilage(idUser);
    if (result.success) {
      setSelectedNodes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleSelectedItems = (event, nodeId) => {
    setSelectedNodeId(nodeId);
  };

  //BFS algorithm to find node by his ID
  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.idBranch === targetId) {
        return currNode;
      }
      if (currNode.childs) {
        queue.push(...currNode.childs);
      }
    }
    return []; // Target node not found
  };

  const [selectedNodes, setSelectedNodes] = useState([]);

  // Retrieve all ids from node to his children's
  function getAllIds(node, idList = []) {
    idList.push(node.idBranch);
    if (node.childs) {
      node.childs.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }
  // Get IDs of all children from specific node
  const getAllChild = (id) => {
    return getAllIds(bfsSearch(branchs, id));
  };

  // Get all father IDs from specific node
  const getAllFathers = (id, list = []) => {
    return [];
  };

  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  function isAllChildrenChecked(node, list) {
    const allChild = getAllChild(node.idBranch);
    const nodeIdIndex = allChild.indexOf(node.idBranch);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) => selectedNodes.concat(list).includes(nodeId));
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation();
    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);
    if (selectedNodes.includes(nodeId)) {
      // Need to de-check
      setSelectedNodes((prevSelectedNodes) => prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id)));
    } else {
      // Need to check
      const ToBeChecked = allChild;

      for (let i = 0; i < fathers.length; ++i) {
        if (isAllChildrenChecked(bfsSearch(branchs, fathers[i]), ToBeChecked)) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelectedNodes((prevSelectedNodes) => [...prevSelectedNodes].concat(ToBeChecked));
    }
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.idBranch}
      nodeId={nodes.idBranch}
      onClick={handleExpandClick}
      label={
        <>
          <input type="checkbox" className="node-check" checked={selectedNodes.indexOf(nodes.idBranch) !== -1} tabIndex={-1} disableRipple onClick={(event) => handleNodeSelect(event, nodes.idBranch)} />
          {nodes.branchName}
        </>
      }
    >
      {Array.isArray(nodes.childs) ? nodes.childs.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <div className="col-md-12">
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">people_alt</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Filial icazələri siyahısı</h4>

          {loading ? (
            <Loading />
          ) : (
            <div className="row">
              <div className="col-md-12 tree-view">
                <TreeView multiSelect defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} selected={selectedNodes}>
                  {branchs.map((node) => renderTree(node))}
                </TreeView>

                {/* <TreeView onNodeSelect={handleSelectedItems} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                  {menus?.map((menu, i) => (
                    <TreeItem nodeId={menu.idMenu} label={menu.description}>
                      {menu?.childs != null ? menu?.childs?.map((child, k) => <TreeItem nodeId={child.idMenu} label={child.description} />) : null}
                    </TreeItem>
                  ))}
                </TreeView> */}
              </div>
              {/* <div className="col-md-2" style={{ textAlign: "center" }}>
                <button className="btn btn-danger" onClick={() => {}}>
                  -
                </button>
                <br />
                <button className="btn btn-info" onClick={handleSelectedMenu}>
                  +
                </button>
              </div>
              <div className="col-md-5 tree-view">
                <TreeView onNodeSelect={() => {}} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                  {selectedMenus?.map((menu, i) => (
                    <TreeItem nodeId={menu.idMenu} label={menu.description}>
                      {menu?.childs != null ? menu?.childs?.map((child, k) => <TreeItem nodeId={child.idMenu} label={child.description} />) : null}
                    </TreeItem>
                  ))}
                </TreeView>
              </div> */}
            </div>
          )}
          <div className="row align-right">
            <CommitButton onClick={addOrUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
}
