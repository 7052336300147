import React from "react";
import TrAppealReportItem from "./TrAppealReportItem";
import TrExpenseReportItem from "./TrExpenseReportItem";
import TrIncomeReportItem from "./TrIncomeReportItem";
import TrTrainerReportItem from "./TrTrainerReportItem";
import TrEmployeeReportItem from "./TrEmployeeReportItem";
import TrCaseReportItem from "./TrCaseReportItem";
import TrStudentReportItem from "./TrStudentReportItem";
import TrGroupReportItem from "./TrGroupReportItem";
import TrJuridicalReportItem from "./TrJuridicalReportItem";
import NotFound from "./../notfound/Index";
import * as util from "../../utils/Util";

const headers = {
  expense: { 1: ["Xərcin növü", "Xərcin adı", "Əməliyyat sayı", "Məbləğ"], 2: ["Tarix", "Əməliyyat sayı", "Məbləğ"] },
  income: { 1: ["Əlavə gəlir adı", "Say", "Məbləğ"], 2: ["Əlavə gəlir adı", "Əməliyyat tarixi", "Əməliyyat nömrəsi", "Məbləğ", "Qeyd", "İstifadəçi adı", "Tarix"] },
  case: { 1: ["Kassanın adı", "Valyuta", "Əvvəlki tarixə qalıq", "Mədaxil", "Məxaric", "Kassa qalığı"], 2: ["Tarix", "Günün əvvəlinə", "Tələbə ödənişi", "Əlavə gəlir ödənişi", "Xaricdə təhsil xidməti ödənişi", "Hüquqi şəxs ödənişi", "Kassaya mədaxil", "Təlimçiyə ödəniş", "Əməkdaşa ödəniş", "Xərc ödənişi", "Kassadan çıxarış", "Günün sonuna"], 3: ["Əməliyyat tarixi", "Mədaxil", "Məxaric", "Əməliyyat", "İstifadəçi adı", "Daxilolma tarixi"] },
  student: { 1: ["Tələbənin adı", "Müqavilə sayı", "Qrup sayı", "Təlim sayı", "İştirak sayı", "Hesablanmış borc", "Ödəniş məbləği"], 2: ["Tam adı", "Əlaqə nömrəsi", "Qruplar", "Təlim sayı", "İştirak sayı", "Son iştirak tarixi"], 3: ["Tam adı", "Əlaqə nömrəsi", "Qruplar", "Təlim sayı", "İştirak sayı", "Son iştirak tarixi", "Qeydiyyat tarixi", "Ödəniş məbləği", "Son ödəniş tarixi", "Borc"], 4: ["Tarix", "Başlanğıc borcu", "Təlim sayı", "Yaranan borc", "Ödəniş", "Yekun", "Sonuna borc"], 5: ["Tələbənin adı", "Qrupların sayı", "Baş tutmalı təlim sayı", "Baş tutmuş təlim sayı", "İştirak sayı", "İştirak edilməyən təlim sayı"], 6: ["Tələbənin adı", "Əlaqə nömrəsi", "Müqavilə sayı", "Qrup sayı", "Borc"] },
  employee: { 1: ["Əməkdaşın adı", "Şöbə", "Vəzifə", "Əmək haqqı", "Avans", "Mükafat", "Bonus", "Yardım", "Digər", "Cəm"], 2: ["Ödənişin növü", "Tarix", "Əməliyyat nömrəsi", "Məbləğ", "Qeyd", "İstifadəçi adı", "Daxilolma tarixi"], 3: ["Assistentin adı", "Qrupların sayı", "Tələbələrin sayı", "Baş tutmalı təlim sayı", "Baş tutmuş təlim sayı", "İştirak sayı"] },
  appeal: { 1: ["Tarix", "Müraciət sayı", "Potensial müştəri", "Uğurlu nəticə"], 2: ["Mənbə", "Müraciət sayı", "Potensial müştəri", "Uğurlu nəticə"], 3: ["Təlimin adı", "Müraciət sayı", "Potensial müştəri", "Uğurlu nəticə"], 4: ["Təlimçinin adı", "Müraciət sayı", "Potensial müştəri", "Uğurlu nəticə"], 5: ["Geri dönüş statusu", "Müraciət sayı", "Potensial müştəri", "Uğurlu nəticə"] },
  trainer: { 1: ["Təlimçinin adı", "Müqavilə sayı", "Aktiv müqavilə sayı", "Qrup sayı", "Tələbə sayı", "Təlim sayı", "Təlimdə iştirak edən tələbə sayı", "Borc"], 2: ["Qrupun adı", "Tələbə sayı", "Təlim planı", "Təlim sayı", "Baş tutan təlim sayı", "İştirak edilən təlim sayı", "Təlim materialı sayı"], 3: [], 4: [], 5: ["Təlimçinin adı", "Qrupların sayı", "Tələbələrin sayı", "Baş tutmalı təlim sayı", "Baş tutmuş təlim sayı", "İştirak sayı"], 6: ["Təlimçinin adı", "Aktiv müqavilə sayı", "Qrup sayı", "Tələbə sayı", "Borc"], 7: ["Təlimçinin adı", "Qrup", "Tələbələr","Hesablanan","Ödənilən", "Borc"] },
  juridical: { 1: ["Hüquqi şəxsin adı", "Müqavilə sayı", "Qrup sayı", "Təlim sayı", "İştirak sayı", "Borc", "Ödəniş məbləği"], 2: [], 3: [], 4: [] },
  group: { 1: ["Qrupun adı", "Təlim növü", "Təlimçi adı", "Qrup üzvləri", "Təlim planı", "Baş tutmalı təlim sayı", "Baş tutmuş təlim sayı", "İştirak etməli tələbələr sayı", "İştirak edən tələbələr sayı"], 2: ["Qrupun adı", "Başlama tarixi", "Təlim növü", "Təlimçi adı", "Qrup üzvləri", "Borc", "Ödəniş məbləği"] },
};

export default function ReportTable({ data, reportType, idReport, pagination }) {
  return (
    <table className="report table">
      <thead>
        <tr>
          <th>No</th>
          {headers[reportType][idReport].map((item) => (
            <th>{item}</th>
          ))}
        </tr>
      </thead>

      {data?.length > 0 ? (
        reportType == "employee" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrEmployeeReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.ept1, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.ept2, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.ept3, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.ept4, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.ept5, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.ept6, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.sum, 0))}</td>
                  </>
                ) : idReport == 2 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.amount, 0))}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupMemberCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.trainingCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalParticipateCount, 0))}</td>
                  </>
                )}
              </tr>
            </tfoot>
          </>
        ) : reportType == "appeal" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrAppealReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                <td></td>
                <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.count, 0))}</td>
                <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.probability, 0))}</td>
                <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.convertedToContract, 0))}</td>
              </tr>
            </tfoot>
          </>
        ) : reportType == "income" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrIncomeReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.count, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.sum, 0))}</td>
                  </>
                ) : idReport == 2 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.amount, 0))}</td>
                  </>
                ) : null}
              </tr>
            </tfoot>
          </>
        ) : reportType == "expense" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrExpenseReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.count, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.sum, 0))}</td>
                  </>
                ) : idReport == 2 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.count, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.sum, 0))}</td>
                  </>
                ) : null}
              </tr>
            </tfoot>
          </>
        ) : reportType == "trainer" ? (
          <>
            <tbody  >{data != null ? data?.map((item, i) => <TrTrainerReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.contractCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.activeContractCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupMemberCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.debt, 0))}</td>
                  </>
                ) : idReport == 2 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupMemberCount, 0))}</td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupPlanCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalParticipatedCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.trainingMaterialCount, 0))}</td>
                  </>
                ): idReport == 6 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.activeContractCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupMemberCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.debt, 0))}</td>
                  </>
                ) :idReport == 7 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groups?.reduce((sumG, itemG) => sumG + itemG?.students?.reduce((sumS, itemS) => sumS + itemS?.debtCalculation, 0), 0), 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groups?.reduce((sumG, itemG) => sumG + itemG?.students?.reduce((sumS, itemS) => sumS + itemS?.paymentSum, 0), 0), 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groups?.reduce((sumG, itemG) => sumG + itemG?.students?.reduce((sumS, itemS) => sumS + itemS?.debt, 0), 0), 0))}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupMemberCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.trainingCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalParticipateCount, 0))}</td>
                  </>
                )}
              </tr>
            </tfoot>
          </>
        ) : reportType == "case" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrCaseReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.beginSum, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.incomeSum, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.costSum, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.endSum, 0))}</td>
                  </>
                ) : idReport == 2 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot3, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot7, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot8, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot9, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot1, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot4, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot5, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot6, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.fot2, 0))}</td>
                    <td></td>
                  </>
                ) : idReport == 3 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.income, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.cost, 0))}</td>
                  </>
                ) : null}
              </tr>
            </tfoot>
          </>
        ) : reportType == "student" ? (
          <>
            {" "}
            <tbody>{data != null ? data?.map((item, i) => <TrStudentReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.contractCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailParticipateCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.debtCalculation, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.paymentSum, 0))}</td>
                  </>
                ) : idReport == 5 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupDaysCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailParticipateCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailNotParticipateCount, 0))}</td>
                  </>
                ) : idReport == 6 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.contractCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.debt, 0))}</td>
                  </>
                ) : null}
              </tr>
            </tfoot>
          </>
        ) : reportType == "group" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrGroupReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupMemberCount, 0))}</td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.trainingCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailParticipateCount, 0))}</td>
                  </>
                ) : idReport == 2 ? (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.memberCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.debtCalculation, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.paymentSum, 0))}</td>
                  </>
                ) : null}
              </tr>
            </tfoot>
          </>
        ) : reportType == "juridical" ? (
          <>
            <tbody>{data != null ? data?.map((item, i) => <TrJuridicalReportItem idReport={idReport} item={item} i={(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)} />) : null}</tbody>
            <tfoot className="tbl-footer">
              <tr>
                <td>Cəm</td>
                {idReport == 1 ? (
                  <>
                    <td></td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.contractCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.groupCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.journalDetailParticipateCount, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.debtCalculation, 0))}</td>
                    <td>{util.FixedNumber(data?.reduce((sum, item) => sum + item?.paymentSum, 0))}</td>
                  </>
                ) : null}
              </tr>
            </tfoot>
          </>
        ) : null
      ) : null}
    </table>
  );
}
