import { Navigate } from "react-router";
import jwt_decode from "jwt-decode";

export default function PrivateRoute({ children, access }) {
  if (localStorage.token != null ) {
    var decoded = jwt_decode(localStorage.token);

    return children;
  }
  return <Navigate to={`/login`} />;
}
