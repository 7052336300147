import { get, post, put, deleteData } from "./core/Requests";

export const getPaymentPeriods = async (pageIndex=0, pageSize=0) => {
  return await get(`/PaymentPeriod`, { pageIndex, pageSize });
};

export const getPaymentPeriod = async (id) => {
  return await get(`/PaymentPeriod/${id}`);
};

export const addPaymentPeriod = async (data) => {
  return await post(`/PaymentPeriod`, data);
};

export const updatePaymentPeriod = async (data) => {
    return await put(`/PaymentPeriod`, data);
  };

export const deletePaymentPeriod = async (id) => {
  return await deleteData(`/PaymentPeriod/${id}`);
};
