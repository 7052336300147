import axios from "axios";
import { URL } from "./Urls";

const TOKEN = "Bearer " + localStorage.token;

function headerAddToken(headers) {
  headers != null
    ? (headers.Authorization = TOKEN)
    : (headers = { Authorization: TOKEN });
  return headers;
}

async function handleResponse(response) {
 // console.log(response.data);
  return await response.data;
}

async function handleError(error) {
  //Alerts.error("error");
  let message = "";
  //   swal({
  //     title: "OOPS!",
  //     text: error,
  //     buttonsStyling: false,
  //     confirmButtonClass: "btn btn-error",
  //     type: "error",
  //   }).catch(swal.noop);
  if (error.response) {
    message = error.response.data.title;
    //  message.error({ content: error.response.data.Message });
    // Request made and server responded
  } else if (error.request) {
    message = error.request;
    // console.log(error.request);
    // The request was made but no response was received
    //  message.error({ content: error.request });
  } else {
    message = error.message;
    // console.log(error.message);
    // Something happened in setting up the request that triggered an Error
    // message.error({ content: error.message });
  }

  return {
    data: null,
    success: false,
    message: message,
  };
}

export async function get(endpoint, headData) {
  let headers = headerAddToken(headData);
  console.log(headers);
  return await axios
    .get(URL.BaseURL + endpoint, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function post(endpoint, data, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .post(URL.BaseURL + endpoint, data, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function put(endpoint, data, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .put(URL.BaseURL + endpoint, data, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteData(endpoint, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .delete(URL.BaseURL + endpoint, { headers })
    .then(handleResponse)
    .catch(handleError);
}

/*

          *********************          with toast         **************************

*/
