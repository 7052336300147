import { get, post, put, deleteData } from "./core/Requests";



export const loginUser = async (data) => {
  return await post(`/Auth/login`, data);
};

export const loginStudent = async (data) => {
  return await post(`/Auth/student/login`, data);
};

export const loginAdmin = async (data) => {
  return await post(`/Auth/admin/login`, data);
};

export const getUserLogs = async (pageIndex=0, pageSize=0) => {
  return await get(`/Auth/getUserLogs`, { pageIndex, pageSize });
};

// export const deleteBranch = async (id) => {
//   return await deleteData(`/Branch/${id}`);
// };

// export const changeActive = async (data) => {
//   return await post(`/Branch/changeActive`, data);
// };
