import React, { useState, useEffect, useRef } from "react";
import { MainContext } from "./contexts/MainContext";
import Routes from "./routes/Index";

import useAddLib from "./helper/useAddLib";
import { useLocation, useParams } from "react-router-dom";
import { useTokenData } from "./helper/useTokenData";
import * as userService from "./services/UserService";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
  // useEffect(() => {
  //   console.log("aappp");
  //   let scripts = [
  //     { src: "/js/bootstrap.min.js" },
  //     { src: "/js/material.min.js" },
  //     { src: "/js/perfect-scrollbar.jquery.min.js" },
  //     { src: "/js/material-dashboard98f3.js" },
  //     { src: "/js/sweetalert2.js" },
  //     { src: "/js/jquery.select-bootstrap.js" }
  // ]
  // //Append the script element on each iteration
  // scripts.forEach(item => {
  //     const script = document.createElement("script")
  //     script.src = item.src
  //     script.async = true
  //     document.body.appendChild(script)
  // })
  // }, [])

  // useAddLib("./js/bootstrap.min.js");
  // useAddLib("./js/material.min.js");
  // useAddLib("./js/perfect-scrollbar.jquery.min.js");
  // useAddLib("./js/material-dashboard98f3.js");
  // useAddLib("./js/sweetalert2.js");
  // useAddLib('./js/jquery.select-bootstrap.js');

  const initialState = {
    menuBackgroundColor: "blue",
    dataBackgroundColor: "blue",
    dataActiveColor: "white",
    pageSize: 10, // localStorage?.rowCount,
    currentPage: 1,
    logoClass: "mini-logo",
    selectedBranch: localStorage?.branch,
    isRighMenuToggle: false,
    theme: localStorage?.theme ?? "blue",
    menuPrivilages: [],
    common:null
  };

  const [state, setState] = useState(initialState);

  // useEffect(() => {
  //   switch (state.theme) {
  //     case "light":
  //       document.body.classList.add('white-content');
  //       break;
  //     case "dark":
  //     default:
  //       document.body.classList.remove('white-content');
  //       break;
  //   }
  // }, [state.theme]);

  const tokenData = useTokenData();
  //  const alias = useOrganization();
  const location = useLocation();

  if (tokenData?.organizationName) {
    document.title = `${tokenData.organizationName}`;

    var desc = document.querySelector("meta[name='description']");
    if (!desc) {
      desc = document.createElement("meta");
      desc.name = "description";
      document.head.appendChild(desc);
    }
    desc.content = `${tokenData.organizationName}`;


    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }

    link.href = localStorage.logo;
  }

  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <MainContext.Provider value={{ state, setState }}>
      <div className="wrapper" data-background-color={state.theme}>
        {/* <TawkMessengerReact ref={tawkMessengerRef} propertyId="64dd205a94cf5d49dc6ace01" widgetId="1h7vscojt" /> */}
        <Routes />
      </div>
    </MainContext.Provider>
  );
}

export default App;
