import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import Pagination from "./../../../components/pagination/Index";
import * as reportService from "./../../../services/ReportService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";

import { Link, useParams } from "react-router-dom";
import * as Alerts from "./../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import PageSizeSelect from "./../../../components/pageSizeSelect/Index";
import * as date from "./../../../utils/DateNow";
import ReportTable from "../../../components/table/ReportTable";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";
import FormInput from "./../../../components/formInput/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [employeeReports, setEmployeeReports] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now(), idEmployee: 0 });

  const alias = useOrganization();
  const { idReport } = useParams();

  const headers = ["Əməkdaşların ümumi hesabatı", "Seçilən əməkdaşın tam hesabatı", "Assistentlər üzrə davamiyyət hesabatı"];

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    if (idReport == 2) {
      setEmployeeReports([]);
      setSelectedEmployee(null);
    } else getEmployeeReports(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  useEffect(() => {
    setEmployeeReports([]);
    selectedEmployee && getEmployeeReports(state.currentPage, localStorage.rowCount);
  }, [selectedEmployee]);

  const getEmployeeReports = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await reportService.getEmployeeReportsByBranch(0, 0, idReport, { ...filter, idBranch: localStorage.branch, idEmployee: selectedEmployee?.rowId });
    if (result?.success) {
      setEmployeeReports(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const Search = (e) => {
    e.preventDefault();
    getEmployeeReports(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assessment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">{headers[idReport - 1]}</h4>
          <div className="row">
           
            {/* <div className="col-md-6">
              <FormInput style={""} label={"Tələbənin tam adı"} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />

            </div> */}
            {/* <div className="col-md-8 row">
              <div className="col-md-8">
                <input type="text" className="form-control f-right" value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
              </div>
              <div className="col-sm-4">
                {selectedEmployee != null ? (
                  <button className="btn btn-danger f-right" onClick={() => setSelectedEmployee(null)}>
                    <span class="material-icons">autorenew</span>
                  </button>
                ) : null}
                {idReport == 2 ? (
                  <>
                    <button className="btn btn-success f-right" onClick={handleEmployeeSelectModal}>
                      Əməkdaş seç
                    </button>
                  </>
                ) : null}
              </div>
            </div> */}
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  {idReport == 2 ? (
                    <>
                      <input type="text" className="form-control ml-10 mr-10" disabled placeholder="Əməkdaşın tam adı" value={selectedEmployee?.fullName} />
                      <button type="button" className="btn btn-success btn-modal-filter" onClick={handleEmployeeSelectModal}>
                        Əməkdaş seç
                      </button>
                    </>
                  ) : null}

                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="text" className="form-control ml-10" placeholder="Axtar" value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <ReportTable data={employeeReports} reportType={"employee"} idReport={idReport} pagination={pagination} />
            </div>
          )}
        </div>
      </div>
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
    </div>
  );
}
