import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import Pagination from "./../../../components/pagination/Index";
import * as groupMemberService from "./../../../services/GroupMemberService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import PageSizeSelect from "./../../../components/pageSizeSelect/Index";

import * as Alerts from "./../../../utils/Alerts";

export default function ViewModal({ isOpen, handleViewModal, groupId }) {
  const { state, setState } = useMainContext();
  const [groupMembers, setGroupMembers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGroupMembers(PAGE_INDEX, localStorage.rowCount);
  }, []);

  const getGroupMembers = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await groupMemberService.getGroupMembersByGroup(pageIndex, pageSize, groupId);
    if (result.success) {
      setGroupMembers(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const changeActive = async (idGroupMember) => {
    let result = await groupMemberService.changeActive(idGroupMember);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getGroupMembers(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    getGroupMembers(pageIndex, localStorage.rowCount);
  };


  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="listGroupMemberModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }} id="listGroupMemberModalLabel">
              {groupMembers[0]?.group?.groupName} üzvləri
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                <PageSizeSelect onChange={(e)=> getGroupMembers(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : groupMembers?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Tələbə</th>
                            <th>Başlama tarixi</th>
                            <th>Aktivlik</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupMembers.map((item,i) => (
                            <tr key={item?.idGroupMember}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.student?.fullName}</td>
                              <td>{item?.startDate?.split('T')[0]}</td>
                              <td>
                                <div className="togglebutton">
                                  <label>
                                    <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idGroupMember)} />
                                    <span className="toggle" />
                                  </label>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div> 
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
