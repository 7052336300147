import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as branchService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [branchSettings, setBranchSettings] = useState({});

  const alias = useOrganization();
  const navigate = useNavigate();

  useEffect(() => {
    getBranchSettings();
  }, [localStorage.branch]);

  const getBranchSettings = async () => {
    let result = await branchService.getBranchSettings(localStorage.branch);
    if (result.success) {
      setBranchSettings(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const updateBranchSettings = async () => {
    let result = await branchService.updateBranchSettings(branchSettings, localStorage.branch);
    if (result.success) {
      Alerts.successWithBack(result.message,`${alias}/branch/settings`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setBranchSettings({ ...branchSettings, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{"Filial parametrləri"}</h4>
                <FormCheckbox label={"Email bildirişdən istifadənin statusu"} style={"col-sm-12"} name={"enableSendingEmailNotification"} checked={branchSettings?.enableSendingEmailNotification} onChange={() => setBranchSettings({ ...branchSettings, enableSendingEmailNotification: branchSettings?.enableSendingEmailNotification == 1 ? 0 : 1 })} />
                <FormCheckbox label={"Sms bildirişdən istifadənin statusu"} style={"col-sm-12"} name={"enableSendingSmsNotification"} checked={branchSettings?.enableSendingSmsNotification} onChange={() => setBranchSettings({ ...branchSettings, enableSendingSmsNotification: branchSettings?.enableSendingSmsNotification == 1 ? 0 : 1 })} />
                <FormCheckbox label={"Valideyn məlumatları"} style={"col-sm-12"} name={"appSettingsParentInfo"} checked={branchSettings?.appSettingsParentInfo} onChange={() => setBranchSettings({ ...branchSettings, appSettingsParentInfo: branchSettings?.appSettingsParentInfo == 1 ? 0 : 1 })}  />

                <div className="row align-right">
                   <CommitButton onClick={updateBranchSettings} />
                  <Link to={`${alias}/branch/settings`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
