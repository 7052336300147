import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as reportService from "../../../services/ReportService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [expenseReports, setExpenseReports] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
    window.open(`${alias}/report/expense/${rowId}`, "_blank");
  };

  useEffect(() => {
    getExpenseReports();
  }, [localStorage.branch]);

  const getExpenseReports = async () => {
    setLoading(true);
    let result = await reportService.getExpenseReportNames();
    setExpenseReports(result);
    setLoading(false);
  };

  return (
    <div className="col-md-12">
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assessment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Xərc hesabatlarının siyahısı</h4>
          {loading ? (
            <Loading />
          ) : expenseReports?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Hesabatın adı</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {expenseReports.map((item,i) => (
                    <tr key={item?.idReport} onClick={() => isSelectRow(item?.idReport)} className={selectedRow === item?.idReport ? "selectedRow" : ""}>
                      <td className="text-center">{i+1}</td>
                      <td>{item?.reportName}</td>
                      <td className="td-actions text-right">
                        <Link target={"_blank"} to={`${alias}/report/Expense/${item?.idReport}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
