import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as studentService from "../../../services/StudentService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import AddStudentNoteModal from "../../studentNote/modal/AddModal";
import * as studentNoteService from "../../../services/StudentNoteService";

import * as Alerts from "../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, header, studentId }) {
  const { state, setState } = useMainContext();
  const [studentNotes, setStudentNotes] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenAddStudentNoteModal, setIsOpenAddStudentNoteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    getStudentNotes(1, localStorage.rowCount);
  }, []);

  const getStudentNotes = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await studentNoteService.getStudentNotes(pageIndex, pageSize, studentId);
    if (result.success) {
      setStudentNotes(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteStudentNote = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await studentNoteService.deleteStudentNote(id);
        if (result.success) {
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getStudentNotes(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const handleAddStudentNoteModal = () => {
    if (isOpenAddStudentNoteModal) {
      setSelectedRow(null);
      getStudentNotes(1, localStorage.rowCount);
    }
    setIsOpenAddStudentNoteModal(!isOpenAddStudentNoteModal);
  };

  const changePage = (pageIndex) => {
    getStudentNotes(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getStudentNotes(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : studentNotes?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Tarix</th>
                            <th>Başlıq</th>
                            <th>Qeyd</th>
                            <th>Rəng</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentNotes.map((item, i) => (
                            <tr key={item}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.noteDate?.split("T")[0]}</td>
                              <td>{item?.title}</td>
                              <td>{item?.note}</td>
                              <td>
                                <span className="select-color-ribbon" style={{ backgroundColor: item?.color, float: "left" }}></span>
                              </td>
                              <td className="td-actions text-right">
                                <button
                                  title="Redaktə"
                                  className="btn btn-info"
                                  onClick={(e) => {
                                    setSelectedRow(item);
                                    handleAddStudentNoteModal();
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                </button>
                                <button
                                  title="Sil"
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    deleteStudentNote(item?.idStudentNote);
                                  }}
                                >
                                  <i className="material-icons">delete</i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn open-blue"
              onClick={(e) => {
                handleAddStudentNoteModal(e);
              }}
            >
              <i className="material-icons">note</i> Yeni qeyd
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenAddStudentNoteModal ? <AddStudentNoteModal isOpen={isOpenAddStudentNoteModal} handleViewModal={handleAddStudentNoteModal} studentId={studentId} selectedRow={selectedRow} /> : null}
    </div>
  );
}
