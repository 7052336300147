import React from "react";
import * as util from "../../utils/Util";

export default function TrExpenseReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item.expenseType}</td>
      <td>{item.expense}</td>
      <td>{item.count}</td>
      <td>{util.FixedNumber(item.sum)}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.date?.split("T")[0]}</td>
      <td>{item?.count}</td>
      <td>{util.FixedNumber(item?.sum)}</td>
    </tr>
  ) : null;
}
