import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "./../contexts/MainContext";

import image from "./../assets/img/sidebar-2.jpg";
import { useOrganization } from "../helper/useOrganization";
import { useMenuPages } from "../helper/useMenuPages";
import { useTokenData } from "../helper/useTokenData";
import logo from "./../assets/logo/edu_w.png";
import * as Alerts from "./../utils/Alerts";
import { useStudent } from "../helper/useStudent";
import * as UserTypes from "./../constants/UserType";
import * as userService from "./../services/UserService";

export default function LeftMenu() {
  const { state, setState } = useMainContext();
  const [path, setPath] = useState("");
  const [mainPath, setMainPath] = useState("");
  const [pages, setPages] = useState([]);
  const [menuPrivilages, setMenuPrivilages] = useState([]);
  const alias = useOrganization();
  const id = useStudent();
  // const pages = useMenuPages(alias);
  const tokenData = useTokenData();
  const exit = async () => {
    await Alerts.confirmForExit(async function (confirmed) {
      if (confirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("rowCount");
        localStorage.removeItem("branch");
        localStorage.removeItem("logo");
        localStorage.removeItem("orgName");
        localStorage.removeItem("userName");
        localStorage.removeItem("userType");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("page");
        localStorage.removeItem("studentId");
        localStorage.removeItem("employeeId");
        localStorage.removeItem("userTypeId");
        localStorage.removeItem("menuPrivilages");
        window.location = tokenData?.isSysUser == "1" ? "/admin/login" : id != null ? `${alias}${id}` : `${alias}`;
      }
    });
  };

  useEffect(() => {
   if(tokenData?.isSysUser == "1" ) setMainPath("management");
    getUserMenuPrivilages();
  }, []);

  useEffect(() => {
    setPages([
      {
        url: `${alias}/dashboard`,
        icon: "dashboard",
        label: "Ana səhifə",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: null,
        isShow: true,
      },
      {
        url: `${alias}/application`,
        icon: "assignment_ind",
        label: "Müraciətlər",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: null,
        isShow: isShowMenu("application"),
      },
      {
        url: `${alias}/student`,
        icon: "person_pin",
        label: "Tələbələr",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: null,
        isShow: isShowMenu("student"),
      },
      {
        url: `${alias}/trainer`,
        icon: "school",
        label: "Təlimçilər",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: null,
        isShow:isShowMenu("trainer"),
      },
      {
        url: `contract`,
        icon: "receipt_long",
        label: "Müqavilələr",
        access: ["organization","branchchief", "branch", "trainer", "student"],
        childs: [
          {
            url: `${alias}/contract/student`,
            icon: "receipt_long",
            label: "Tələbə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("contract/student"),
          },
          {
            url: `${alias}/contract/juridical`,
            icon: "receipt_long",
            label: "Hüquqi şəxs",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("contract/juridical"),
          },
          {
            url: `${alias}/contract/trainer`,
            icon: "receipt_long",
            label: "Təlimçi",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("contract/trainer"),
          },
          {
            url: `${alias}/contract/employee`,
            icon: "receipt_long",
            label: "Əməkdaş",
            access: ["organization","branchchief", "branch"],
            isShow: isShowMenu("contract/employee"),
          },
        ],
      },
      {
        url: `${alias}/group`,
        icon: "groups",
        label: "Qruplar",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: null,
        isShow: isShowMenu("group"),
      },
      {
        url: `${alias}/journal`,
        icon: "list_alt",
        label: "Davamiyyət",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: null,
        isShow: isShowMenu("journal"),
      },
      {
        url: `attendance`,
        icon: "auto_awesome",
        label: "Əlavələr",
        access: ["organization","branchchief", "branch", "trainer", "student"],
        childs: [
          {
            url: `${alias}/review`,
            icon: "comment",
            label: "Rəylər",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("review"),
          },
          {
            url: `${alias}/assignment`,
            icon: "assignment",
            label: "Tapşırıqlar",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("assignment"),
          },
          {
            url: `${alias}/trainingMaterial`,
            icon: "attachment",
            label: "Təlim materialları",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("assignment"),
          },          {
            url: `${alias}/groupTrainingPlan`,
            icon: "app_registration",
            label: "Qrup təlim planları",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("assignment"),
          },
          {
            url: `${alias}/exam`,
            icon: "chrome_reader_mode",
            label: "İmtahan nəticələri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("exam"),
          },
          {
            url: `${alias}/calendar`,
            icon: "event",
            label: "Təqvim",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("calendar"),
          },
        ],
      },
      {
        url: `operations`,
        icon: "widgets",
        label: "Maliyyə əməliyyatları",
        access: ["organization","branchchief", "branch", "trainer", "student"],
        childs: [
          {
            url: `${alias}/financeOperation/3`,
            icon: "paid",
            label: "Tələbə ödənişləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/3"),
          },
          {
            url: `${alias}/financeOperation/9`,
            icon: "paid",
            label: "Hüquqi şəxs ödənişləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/9"),
          },
          {
            url: `${alias}/financeOperation/4`,
            icon: "paid",
            label: "Təlimçi ödənişləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/4"),
          },
          {
            url: `${alias}/financeOperation/5`,
            icon: "paid",
            label: "Əməkdaş ödənişləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/5"),
          },
          {
            url: `${alias}/financeOperation/6`,
            icon: "paid",
            label: "Xərc ödənişləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/6"),
          },
          {
            url: `${alias}/financeOperation/7`,
            icon: "paid",
            label: "Əlavə gəlir ödənişləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/7"),
          },
          {
            url: `${alias}/financeOperation/1-2`,
            icon: "paid",
            label: "Kassa əməliyyatları",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("financeOperation/1-2"),
          },
        ],
      },
      {
        url: `report`,
        icon: "assessment",
        label: "Hesabatlar",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: [
          {
            url: `${alias}/report/application`,
            icon: "assessment",
            label: "Müraciətlər üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/application"),
          },
          {
            url: `${alias}/report/trainer`,
            icon: "assessment",
            label: "Təlimçilər üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/trainer"),
          },
          {
            url: `${alias}/report/employee`,
            icon: "assessment",
            label: "Əməkdaşlar üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/employee"),
          },
          {
            url: `${alias}/report/student`,
            icon: "assessment",
            label: "Tələbələr üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/student"),
          },
          {
            url: `${alias}/report/juridical`,
            icon: "assessment",
            label: "Hüquqi şəxslər üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/juridical"),
          },
          {
            url: `${alias}/report/case`,
            icon: "assessment",
            label: "Kassalar üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/case"),
          },
          {
            url: `${alias}/report/income`,
            icon: "assessment",
            label: "Əlavə gəlirlər üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/income"),
          },
          {
            url: `${alias}/report/expense`,
            icon: "assessment",
            label: "Xərclər üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/expense"),
          },
          {
            url: `${alias}/report/group`,
            icon: "assessment",
            label: "Qruplar üzrə",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("report/group"),
          },
        ],
      },
      {
        url: `handbook`,
        icon: "menu_book",
        label: "Sorğu kitabçası",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: [
          {
            url: `${alias}/department`,
            icon: "reduce_capacity",
            label: "Şöbələr",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("department"),
          },
          {
            url: `${alias}/post`,
            icon: "cases",
            label: "Vəzifələr",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("post"),
          },
          {
            url: `${alias}/employee`,
            icon: "badge",
            label: "Əməkdaşlar",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("employee"),
          },
          {
            url: `${alias}/room`,
            icon: "meeting_room",
            label: "Otaqlar",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("room"),
          },
          {
            url: `${alias}/juridical`,
            icon: "assignment_ind",
            label: "Hüquqi şəxslər",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("juridical"),
          },
          {
            url: `${alias}/expense`,
            icon: "energy_savings_leaf",
            label: "Xərc adları",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("expense"),
          },
          {
            url: `${alias}/income`,
            icon: "paid",
            label: "Əlavə gəlir adları",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("income"),
          },
          {
            url: `${alias}/trainingType`,
            icon: "article",
            label: "Təlim növləri",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("trainingType"),
          },
        ],
      },
      {
        url: `faq`,
        icon: "info",
        label: "Faydalı keçidlər",
        access: ["organization","branchchief", "branch", "trainer"],
        childs: [
          {
            url: `${alias}/userules`,
            icon: "assignment",
            label: "İstifadə qaydaları",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("userules"),
          },
          {
            url: `${alias}/download`,
            icon: "download",
            label: "Yükləmələr",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu("download"),
          },
          {
            url: `#`,
            icon: "lightbulb",
            label: "Yeniliklər",
            access: ["organization","branchchief", "branch", "trainer"],
            isShow: isShowMenu(""),
          },
        ],
      },
      {
        url: `management`,
        icon: "settings",
        label: "İdarəetmə",
        access: ["system", "organization"],
        childs: [
          {
            url: `${alias}/organization`,
            icon: "account_balance",
            label: "Təşkilat",
            access: ["system"],
            isShow: true,
          },
          {
            url: `${alias}/branch`,
            icon: "domain",
            label: "Filiallar",
            access: ["system"],
            isShow: true,
          },
          {
            url: `${alias}/notify`,
            icon: "notifications_active",
            label: "Bildiriş şablonları",
            access: ["organization","branchchief", "branch"],
            isShow: isShowMenu("notify"),
          },
          {
            url: `${alias}/user`,
            icon: "people_alt",
            label: "İstifadəçilər",
            access: ["organization","branchchief", "branch"],
            isShow: isShowMenu("user"),
          },
          {
            url: `${alias}/user`,
            icon: "people_alt",
            label: "İstifadəçilər",
            access: ["system"],
            isShow: true,
          },
          {
            url: `${alias}/branch/settings`,
            icon: "settings",
            label: "Filial parametrləri",
            access: ["organization","branchchief", "branch"],
            isShow: isShowMenu("branch/settings"),
          },
          {
            url: `${alias}/case`,
            icon: "currency_exchange",
            label: "Kassalar",
            access: ["organization","branchchief", "branch"],
            isShow: isShowMenu("case"),
          },
          {
            url: `${alias}/sysuser`,
            icon: "domain",
            label: "İnzibatçılar",
            access: ["system"],
            isShow: true,
          },
          {
            url: `${alias}/userLog`,
            icon: "people_alt",
            label: "İstifadəçi loqları",
            access: ["system"],
            isShow: true,
          },
          {
            url: `${alias}/organizationPayment`,
            icon: "payments",
            label: "Təşkilat ödənişləri",
            access: ["system"],
            isShow: true,
          },
        ],
      },
      {
        url: `${alias}${id}/dashboard`,
        icon: "dashboard",
        label: "Ana səhifə",
        access: ["student"],
        childs: null,
        isShow: true,
      },
      {
        url: `${alias}${id}/contract/student`,
        icon: "receipt_long",
        label: "Müqavilələr",
        access: ["student"],
        isShow: true,
      },
      {
        url: `${alias}${id}/group`,
        icon: "groups",
        label: "Qruplar",
        access: ["student"],
        childs: null,
        isShow: true,
      },
      {
        url: `${alias}${id}/review`,
        icon: "comment",
        label: "Rəylər",
        access: ["student"],
        isShow: true,
      },
      {
        url: `${alias}${id}/assignment`,
        icon: "assignment",
        label: "Tapşırıqlar",
        access: ["student"],
        isShow: true,
      },
      {
        url: `${alias}${id}/trainingMaterial`,
        icon: "attachment",
        label: "Təlim materialları",
        access: ["student"],
        isShow: true,
      },
      {
        url: `${alias}${id}/financeOperation/3`,
        icon: "paid",
        label: "Ödənişlər",
        access: ["student"],
        isShow: true,
      },
    ]);
  }, [menuPrivilages]);

  const setMenuPath = async (path) => {
    setPath(path);
    setState({ ...state, currentPage: 1, isRighMenuToggle: !(window.innerWidth <= 768) });
    if (document.body.className !== "sidebar-mini") {
      document.body.className = window.innerWidth <= 768 ? "" : "nav-open";
    }
  };

  const getUserMenuPrivilages = async () => {
    let result = await userService.getUserMenuPrivilages();
    if (result.success) {
      setMenuPrivilages(result.data);
      localStorage.menuPrivilages = JSON.stringify(result.data);
    }
  };

  const isShowMenu = (url) => {
    return menuPrivilages.some((x) => x.menuName == url);
  };

  const userTypes = ["system", "organization","branchchief", "branch","trainer","student"];
  return (
    <div
      className="sidebar"
      data-active-color={state.dataActiveColor}
      data-background-color={state.theme}
      data-image={image}
      //  style={{ backgroundImage: `url(${image})` }}
    >
      <div style={{ backgroundImage: `url(${image})` }}></div>
      <div className="sidebar-wrapper ps-active-y">
        <div className="user" style={{ textAlign: "center" }}>
          <img className={state.logoClass} src={tokenData.userTypeId == UserTypes.SYSTEM_USER ? logo : localStorage.logo} />

          {/* <div className="photo">
            <img src={image} />
          </div>
          <div className="info">
            <a
              data-toggle="collapse"
              href="#collapseExample"
              className="collapsed"
            >
              <span style={{ fontFamily: "Poppins" }}>
                Tania Andrew
                <b className="caret" />
              </span>
            </a>
            <div className="clearfix" />
            <div className="collapse" id="collapseExample">
              <ul className="nav">
                <li>
                  <a href="#">
                    <span className="sidebar-mini"> MP </span>
                    <span className="sidebar-normal"> My Profile </span>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className={`user ${state.logoClass != "mini-logo" || tokenData.userTypeId ==UserTypes.STUDENT_USER ? "" : "hidden"}`} style={{ textAlign: "center" }}>
          {/* <p className="org-name">{localStorage.orgName}</p> */}
          <span className="user-name">{localStorage.userName}</span>
          {/* <br />
          <span className="user-role">({localStorage.userType})</span> */}
        </div>
        <ul className="nav">
          {pages.map((page) =>
            page.childs == null ? (
              // -1 !== state.menuPrivilages.findIndex((obj) => obj.menuName && page.url.toLowerCase() == `${alias}${id}/${obj.menuName.toLowerCase()}`) &&
              page.isShow && page.access.includes(userTypes[tokenData.userTypeId]) ? (
                <li className={path == page.url ? "active" : ""}>
                  <Link to={page.url} onClick={() => setMenuPath(page.url)}>
                    <i className="material-icons">{page.icon}</i>
                    <p> {page.label} </p>
                  </Link>
                </li>
              ) : null
            ) : //  -1 !== state.menuPrivilages.findIndex((obj) => obj.menuName && -1 !== page.childs.findIndex((child) => child.url.toLowerCase() == `${alias}/${obj.menuName.toLowerCase()})}`)) &&
            page?.childs?.some((x) => x.isShow) && page.access.includes(userTypes[tokenData.userTypeId]) ? (
              <li className={mainPath === page.url ? "active" : ""}>
                <a data-toggle="collapse" aria-expanded={mainPath === page.url} href="javascript:void(0)" onClick={() => setMainPath(mainPath != page.url ? page.url : "")}>
                  <i className="material-icons">{page.icon}</i>
                  <p>
                    {page.label}
                    <b className="caret" />
                  </p>
                </a>
                <div className={`collapse ${mainPath === page.url ? "in" : ""}`}>
                  <ul className="nav ml-15">
                    {page.childs.map((child) =>
                      // -1 !== state.menuPrivilages.findIndex((obj) => obj.menuName && child.url.toLowerCase() == `${alias}/${obj.menuName.toLowerCase()}`) &&
                      child.isShow && child.access.includes(userTypes[tokenData.userTypeId]) ? (
                        <li className={path === child.url ? "active" : ""}>
                          <Link to={child.url} onClick={() => setMenuPath(child.url)}>
                            <i className="material-icons">{child.icon}</i>
                            <p> {child.label} </p>
                          </Link>
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              </li>
            ) : null
          )}

          {/* {tokenData.userTypeId == 1 ? (
            <li>
              <Link target={"_blank"} to={`${alias}/chat`}>
                <i className="material-icons">{"contact_support"}</i>
                <p> {"Onlayn dəstək"} </p>
              </Link>
            </li>
          ) : null} */}

          <li className={path == "/exit" ? "active" : ""}>
            <a href="javascript:void(0)" onClick={exit}>
              <i className="material-icons">exit_to_app</i>
              <p>Çıxış </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
