import React, { useState, useEffect } from "react";
import * as groupService from "./../../../services/GroupService";
import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import Select from "./../../../components/select/Index";
import { useOrganization } from "../../../helper/useOrganization";
import * as contractService from "./../../../services/ContractService";

import { useMainContext } from "./../../../contexts/MainContext";
import * as paymentPeriodService from "./../../../services/PaymentPeriodService";
import * as groupMemberService from "./../../../services/GroupMemberService";
import StudentSelectModal from "./../../student/modal/SelectModal";
import * as date from "./../../../utils/DateNow";

export default function AddModal({ isOpen, handleModal, setAddedGroupMember }) {
  const { state, setState } = useMainContext();

  const [groupMember, setGroupMember] = useState({ student: {}, startDate: date.Now() });
  const [paymentPeriods, setPaymentPeriods] = useState([]);
  const [contractStudents, setContractStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isOpenStudentSelectModal, setIsOpenStudentSelectModal] = useState(false);
  const alias = useOrganization();

  useEffect(() => {
    getPaymentPeriods();
  }, []);

  useEffect(() => {
    if (selectedStudent?.rowId != null) getContractStudents(selectedStudent?.rowId);
    else setContractStudents([]);
  }, [selectedStudent]);

  const getPaymentPeriods = async () => {
    let result = await paymentPeriodService.getPaymentPeriods();
    if (result.success) {
      setPaymentPeriods(result.data.datas);
    } else {
      // Alerts.error(result.message);
    }
  };

  const getContractStudents = async (studentId) => {
    let result = await contractService.getContractStudentsByStudent(0, 0, studentId);
    if (result.success) {
      setContractStudents(result?.data?.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const addGroupMember = async () => {
    if (selectedStudent) {
      groupMember.student.idStudent = selectedStudent?.rowId;
      groupMember.student.fullName = selectedStudent?.fullName;
      setAddedGroupMember(groupMember);
      handleModal();
    } else {
      Alerts.error("Tələbə seçilməyib");
    }
  };

  const handleInput = (e) => {
    setGroupMember({ ...groupMember, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setGroupMember({ ...groupMember, [name]: e.value });
  };

  const handleStudentSelectModal = () => {
    setIsOpenStudentSelectModal(!isOpenStudentSelectModal);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="addJournalModal" aria-labelledby="addPostModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Yeni qrup üzvü
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <>
                    <FormInput style={"col-sm-7"} label={"Tələbənin tam adı"} value={selectedStudent != null ? selectedStudent.fullName : ""} disabled={true} />
                    <div className="form-group col-sm-5">
                      <label className="label-control w-100"></label>
                      {selectedStudent != null ? (
                        <button className="btn btn-danger" onClick={() => setSelectedStudent(null)}>
                          <span class="material-icons">autorenew</span>
                        </button>
                      ) : null}
                      <button className="btn btn-info" onClick={handleStudentSelectModal}>
                        Tələbə seç
                      </button>
                    </div>
                  </>
                  <Select placeholder={"Seç"} style={"col-sm-6"} label={"Müqavilə"} changeSelectHandler={(e) => handleSelect(e, "fkIdContractStudent")} selectedValue={groupMember?.fkIdContractStudent} options={contractStudents?.map((item) => ({ label: `${item?.trainingType?.description} (${item?.contractDate?.split("T")[0]})`, value: item?.idContractStudent }))}  />
                  <FormInput style={"col-sm-6"} label={"Başlama tarixi"} name={"startDate"} type={"date"} value={groupMember?.startDate} onChange={handleInput} />
                  <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} onChange={() => setGroupMember({ ...groupMember, isActive: groupMember.isActive == 1 ? 0 : 1 })} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-success" onClick={addGroupMember}>
              Təsdiq et
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenStudentSelectModal ? <StudentSelectModal isOpen={isOpenStudentSelectModal} handleStudentSelectModal={handleStudentSelectModal} setSelectedStudent={setSelectedStudent} /> : null}
    </div>
  );
}
