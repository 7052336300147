import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as userService from "../../services/UserService";
import * as helperService from "../../services/HelperService";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { useTokenData } from "../../helper/useTokenData";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Box } from "@mui/material";
//import Checkbox from "@material-ui/core/Checkbox";
import CommitButton from "../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [menus, setMenus] = useState([]);
  const [selectedNodeId, setSelectedNodeId] = useState();
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();
  const { idUser } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getMenus(idUser);
  }, []);

  const addOrUpdate = async () => {
    if (idUser) {
      let request = { fkIdUserRelated: idUser, fkIdMenus: [...new Set(selectedNodes)] };
      let result = await userService.addUserMenuPrivilage(request, localStorage.branch);
      if (result?.success) {
        Alerts.successWithBack(result.message, `${alias}/user`, navigate);
      } else {
        Alerts.error(result.message);
      }
    } else Alerts.error("!");
  };

  const getMenus = async (idUser) => {
    setLoading(true);
    let result = await helperService.getMenus(idUser);
    if (result.success) {
      setMenus(result.data);
      getUserMenuPrivilages();
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const getUserMenuPrivilages = async () => {
    let result = await userService.getUserMenuPrivilage(idUser);
    if (result.success) {
      setSelectedNodes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleSelectedMenu = async () => {
    console.log(selectedNodeId);
    var filteredMenu = menus.find((x) => x.idMenu == selectedNodeId);
    console.log(filteredMenu);
    //if(filteredMenu.childs==null)
    //setSelectedMenus({ ...selectedMenus, filteredMenu });
  };

  const handleSelectedItems = (event, nodeId) => {
    setSelectedNodeId(nodeId);
  };

  //BFS algorithm to find node by his ID
  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.idMenu === targetId) {
        return currNode;
      }
      if (currNode.childs) {
        queue.push(...currNode.childs);
      }
    }
    return []; // Target node not found
  };

  const [selectedNodes, setSelectedNodes] = useState([]);
  useEffect(() => {
    console.log("Selected Nodes:");
    console.log(JSON.stringify(selectedNodes, null, 4));
  }, [selectedNodes]);

  // Retrieve all ids from node to his children's
  function getAllIds(node, idList = []) {
    idList.push(node.idMenu);
    if (node.childs) {
      node.childs.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }
  // Get IDs of all children from specific node
  const getAllChild = (id) => {
    return getAllIds(bfsSearch(menus, id));
  };

  // Get all father IDs from specific node
  const getAllFathers = (id, list = []) => {
    return [];
    const node = bfsSearch(menus, id);
    if (node.childs != null) {
      list.push(node.idMenu);
      return getAllFathers(node.idMenu, list);
    }
    return list;
  };

  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  function isAllChildrenChecked(node, list) {
    const allChild = getAllChild(node.idMenu);
    const nodeIdIndex = allChild.indexOf(node.idMenu);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) => selectedNodes.concat(list).includes(nodeId));
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation();
    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);
    if (selectedNodes.includes(nodeId)) {
      // Need to de-check
      setSelectedNodes((prevSelectedNodes) => prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id)));
    } else {
      // Need to check
      const ToBeChecked = allChild;

      for (let i = 0; i < fathers.length; ++i) {
        if (isAllChildrenChecked(bfsSearch(menus, fathers[i]), ToBeChecked)) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelectedNodes((prevSelectedNodes) => [...prevSelectedNodes].concat(ToBeChecked));
    }
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.idMenu}
      nodeId={nodes.idMenu}
      onClick={handleExpandClick}
      label={
        <>
          <input type="checkbox" className="node-check" checked={selectedNodes.indexOf(nodes.idMenu) !== -1} tabIndex={-1} disableRipple onClick={(event) => handleNodeSelect(event, nodes.idMenu)} />
          {nodes.description} 
          {nodes?.alert != -1 ? (
            <i className="material-icons" style={{ color: `${nodes?.alert == 0 ? "#e8c303" : nodes?.alert == 1 ? "red" : ""}` }}>
              error
            </i>
          ) : null}
        </>
      }
    >
      {Array.isArray(nodes.childs) ? nodes.childs.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <div className="col-md-12">
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">people_alt</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Menyu icazələri siyahısı</h4>

          {loading ? (
            <Loading />
          ) : (
            <div className="row">
              <div className="col-md-12 tree-view">
                <TreeView multiSelect defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} selected={selectedNodes}>
                  {/* <TreeItem
                    label={
                      <>
                        <input type="checkbox" className="node-check" checked={true} />
                        {"Yalnız özünə məxsus məlumatları idarə edə bilsin"}
                      </>
                    }
                  ></TreeItem> */}

                  {menus.map((node) => renderTree(node))}
                </TreeView>

                {/* <TreeView onNodeSelect={handleSelectedItems} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                  {menus?.map((menu, i) => (
                    <TreeItem nodeId={menu.idMenu} label={menu.description}>
                      {menu?.childs != null ? menu?.childs?.map((child, k) => <TreeItem nodeId={child.idMenu} label={child.description} />) : null}
                    </TreeItem>
                  ))}
                </TreeView> */}
              </div>
              {/* <div className="col-md-2" style={{ textAlign: "center" }}>
                <button className="btn btn-danger" onClick={() => {}}>
                  -
                </button>
                <br />
                <button className="btn btn-info" onClick={handleSelectedMenu}>
                  +
                </button>
              </div>
              <div className="col-md-5 tree-view">
                <TreeView onNodeSelect={() => {}} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                  {selectedMenus?.map((menu, i) => (
                    <TreeItem nodeId={menu.idMenu} label={menu.description}>
                      {menu?.childs != null ? menu?.childs?.map((child, k) => <TreeItem nodeId={child.idMenu} label={child.description} />) : null}
                    </TreeItem>
                  ))}
                </TreeView>
              </div> */}
            </div>
          )}
          <div className="row align-right">
            <CommitButton onClick={addOrUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
}
