import { get, post, put, deleteData } from "./core/Requests";

export const getStudentReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/student/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getStudentReports = async () => {
  return [
    { idReport: 6, reportName: "Tələbələrin ümumi hesabatı" },
    { idReport: 1, reportName: "Tələbələrin ümumi hesabatı (iki tarix aralığı üzrə)" },
  //  { idReport: 2, reportName: "Tələbələrin təlimlər üzrə hesabatı" },
 //   { idReport: 3, reportName: "Tələbələrin detallı hesabatı" },
  //  { idReport: 4, reportName: "Seçilən tələbənin tam hesabatı" },
    { idReport: 5, reportName: "Tələbələr üzrə davamiyyət hesabatı" },
  ];
};

export const getJuridicalReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/juridical/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getJuridicalReports = async () => {
  return [
    { idReport: 1, reportName: "Hüquqi şəxslərin ümumi hesabatı" },
  //  { idReport: 2, reportName: "Hüquqi şəxslərin təlimlər üzrə hesabatı" },
  //  { idReport: 3, reportName: "Hüquqi şəxslərin detallı hesabatı" },
  //  { idReport: 4, reportName: "Seçilən hüquqi şəxsin tam hesabatı" },
  ];
  //    return await get(`/Room`, { pageIndex, pageSize });
};

export const getCaseReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/case/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getCaseReportNames = async () => {
  return [
    { idReport: 1, reportName: "Kassaların ümumi hesabatı" },
    { idReport: 2, reportName: "Kassanın günlər üzrə tam hesabatı" },
    { idReport: 3, reportName: "Kassa mədaxil və məxaric hesabatı" },
  ];
};

export const getEmployeeReportNames = async () => {
  return [
    { idReport: 1, reportName: "Əməkdaşların ümumi hesabatı" },
    { idReport: 2, reportName: "Seçilən əməkdaşın tam hesabatı" },
   // { idReport: 3, reportName: "Assistentlər üzrə davamiyyət hesabatı" },
  ];
};

export const getEmployeeReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/employee/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getTrainerReportNames = () => {
  return [
    { idReport: 6, reportName: "Təlimçilərin ümumi hesabatı" },
    { idReport: 1, reportName: "Təlimçilərin ümumi hesabatı (iki tarix aralığı üzrə)" },
    { idReport: 2, reportName: "Seçilən təlimçinin qruplar üzrə hesabatı" },
  //  { idReport: 3, reportName: "Təlimçilərin detallı hesabatı" },
  //  { idReport: 4, reportName: "Seçilən təlimçinin tam hesabatı" },
  { idReport: 5, reportName: "Təlimçilər üzrə davamiyyət hesabatı" },
  { idReport: 7, reportName: "Təlimçilərin qruplar və tələbələr üzrə ödəniş hesabatı" },
];
};

export const getTrainerReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/trainer/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getExpenseReportNames = () => {
  return [
    { idReport: 1, reportName: "Xərclərin ümumi hesabatı" },
    { idReport: 2, reportName: "Xərclərin günlər üzrə hesabatı" },
  ];
};

export const getExpenseReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/expense/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getIncomeReportNames = () => {
  return [
    { idReport: 1, reportName: "Əlavə gəlirlərin ümumi hesabatı" },
    { idReport: 2, reportName: "Əlavə gəlirlərin günlər üzrə hesabatı" },
  ];
};

export const getIncomeReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/income/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getAttendanceReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/attendance/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

// export const getAttendanceReportNames = async () => {
//   return [
//     { idReport: 1, reportName: "Təlimçilər üzrə davamiyyət hesabatı" },
//     { idReport: 2, reportName: "Assistentlər üzrə davamiyyət hesabatı" },
//     { idReport: 3, reportName: "Qruplar üzrə davamiyyət hesabatı" },
//     { idReport: 4, reportName: "Tələbələr üzrə davamiyyət hesabatı" },
//   ];
// };

export const getGroupReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/group/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getGroupReports = async () => {
  return [
    { idReport: 1, reportName: "Qruplar üzrə davamiyyət hesabatı" },
    { idReport: 2, reportName: "Qruplar üzrə ödəniş hesabatı" },
  ];
};

export const getAppealReportNames = () => {
  return [
    { idReport: 1, reportName: "Müraciətlərin günlər üzrə hesabatı" },
    { idReport: 2, reportName: "Müraciətlərin mənbələr üzrə hesabatı" },
    { idReport: 3, reportName: "Müraciətlərin təlimlər üzrə hesabatı" },
    { idReport: 4, reportName: "Müraciətlərin təlimçilər üzrə hesabatı" },
    { idReport: 5, reportName: "Müraciətlərin geri zəng statusları üzrə hesabatı" },
  ];
};

export const getAppealReportsByBranch = async (pageIndex = 0, pageSize = 0, idReport, filter) => {
  return await post(`/Report/application/${idReport}`, filter, {
    pageIndex,
    pageSize,
  });
};
