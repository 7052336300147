import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import * as helperService from "./../../services/HelperService";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import NotFound from "./../../components/notfound/Index";
import HeadCardItem from "../../components/dashboard/HeadCardItem";
import PieChart from "../../components/chart/PieChart";
import { useTranslation } from "react-i18next";

export default function Index() {
  const { state, setState } = useMainContext();
  const [dashboard, setDashboard] = useState();
  const [trainingData, setTrainingData] = useState([]);

  const alias = useOrganization();
  const { t, i18n } = useTranslation()

  useEffect(() => {
    getDashboardHead();
  }, [localStorage.branch, t]);

  const getDashboardHead = async () => {
    if (localStorage.branch != undefined) {
      let result = await helperService.getDashboardHead(localStorage.branch);
      if (result.success) {
        setDashboard(result.data);
        setTrainingData(
          [
            {
              type: t('dashboard.todayTrainings'),
              value: result.data?.trainingStatistics?.daily_count,
            },
            {
              type: t('dashboard.finishTrainings'),
              value: result.data?.trainingStatistics?.completed_count,
            },
          ]
        );
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const trainingColor = ["#66bb6a", "#ffa726"];

  const paymentColor = ["#f44336", "#66bb6a"];

  const paymentData = [
    {
      type: t('dashboard.todayPayments'),
      value: 62,
    },
    {
      type: t('dashboard.allPayments'),
      value: 45,
    },
  ];

  return (
    <div className="ml-10 mr-10">
      <div className="row">
        <HeadCardItem header={t("dashboard.applications")} theme={state.theme} count={dashboard?.applicationCount} icon={"assignment_ind"} desc={t('dashboard.description')} url={`${alias}/application`} />
        <HeadCardItem header={t("dashboard.students")} theme={state.theme} count={dashboard?.studentCount} icon={"people_alt"} desc={t('dashboard.description')} url={`${alias}/student`} />
        <HeadCardItem header={t("dashboard.groups")} theme={state.theme} count={dashboard?.groupCount} icon={"groups"} desc={t('dashboard.description')} url={`${alias}/group`} />
        <HeadCardItem header={t("dashboard.employees")} theme={state.theme} count={dashboard?.employeeCount} icon={"badge"} desc={t('dashboard.description')}  url={`${alias}/employee`} />
        <HeadCardItem header={t("dashboard.trainerContracts")} theme={state.theme} count={dashboard?.trainerContractCount} icon={"receipt_long"} desc={t('dashboard.description')}  url={`${alias}/contract/trainer`} />
        <HeadCardItem header={t("dashboard.studentContracts")} theme={state.theme} count={dashboard?.studentContractCount} icon={"receipt_long"} desc={t('dashboard.description')}  url={`${alias}/contract/student`} />
        </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card" data-background-color={state.theme}>
            <div className="card-header card-header-icon" data-background-color={state.theme}>
              <i className="material-icons">pie_chart</i>
            </div>
            <div className="card-content">
              <h4 className="card-title">{t("dashboard.trainings")}</h4>
              <PieChart data={trainingData} color={trainingColor} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card" data-background-color={state.theme}>
            <div className="card-header card-header-icon" data-background-color={state.theme}>
              <i className="material-icons">pie_chart</i>
            </div>
            <div className="card-content">
              <h4 className="card-title">{t("dashboard.payments")}</h4>
              <PieChart data={paymentData} color={paymentColor} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
