import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import Pagination from "./../../../components/pagination/Index";
import * as reportService from "./../../../services/ReportService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";

import { Link, useParams } from "react-router-dom";
import * as Alerts from "./../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import PageSizeSelect from "./../../../components/pageSizeSelect/Index";
import * as date from "./../../../utils/DateNow";
import ReportTable from "../../../components/table/ReportTable";

export default function Index() {
  const { state, setState } = useMainContext();
  const [studentReports, setStudentReports] = useState([]);
  const [studentFilteredReports, setStudentFilteredReports] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });

  const alias = useOrganization();
  const { idReport } = useParams();

  const headers = ["Tələbələrin ümumi hesabatı (iki tarix aralığı üzrə)", "Tələbələrin təlimlər üzrə hesabatı", "Tələbələrin detallı hesabatı", "Seçilən tələbənin tam hesabatı", "Tələbələr üzrə davamiyyət hesabatı","Tələbələrin ümumi hesabatı"];

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getStudentReports(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getStudentReports = async (pageIndex, pageSize) => {
    setLoading(true);
    filter.idBranch = localStorage.branch;
    let result = await reportService.getStudentReportsByBranch(0, 0, idReport, filter);
    if (result.success) {
      setStudentReports(result.data.datas);
      setStudentFilteredReports(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const Search = (e) => {
    e.preventDefault();
    getStudentReports(PAGE_INDEX, localStorage.rowCount);
  };

  const SearchInTable = (common) => {
    const filteredItems = studentReports.filter((item) => item.studentFullName.toLowerCase().includes(common.toLowerCase()));
    setFilter({ ...filter, common: common });
    setStudentFilteredReports(filteredItems);
  };

  return (
    <div className="col-md-12">
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assessment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">{headers[idReport - 1]}</h4>
          <div className="row">
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  {idReport != 6 ? (
                    <>
                      <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                      <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                    </>
                  ) : null}
                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => SearchInTable(e.target.value)} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <ReportTable data={studentFilteredReports} reportType={"student"} idReport={idReport} pagination={pagination} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
