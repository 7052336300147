export const URL = {
 //   BaseURL: "https://localhost:44358/api",
  //BaseURL: "http://62.171.159.155:8080/api",
  BaseURL: "https://app.e-edu.az/api/api/api",
  //BaseAttachURL: 'https://localhost:44358/files/',
};

//export const BaseAttachURL= 'https://localhost:44358/files/';
export const BaseAttachURL = "https://app.e-edu.az/api/files/";
//export const BaseAttachURL= 'http://62.171.159.155:8080/files/';

export const FileRoutes = {
  StudentRoute: "students/",
  EmployeeRoute: "employees/",
  OrganizationRoute: "organizations/",
  TrainingMaterialRoute: "trainingMaterials/",
};
