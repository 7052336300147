import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import * as helperService from "../../services/HelperService";

import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import NotFound from "../../components/notfound/Index";
import HeadCardItem from "../../components/dashboard/HeadCardItem";
import PieChart from "../../components/chart/PieChart";
import { useTranslation } from "react-i18next";
import { useStudent } from "../../helper/useStudent";

export default function Index() {
  const { state, setState } = useMainContext();
  const [dashboard, setDashboard] = useState();

  const alias = useOrganization();
  const id = useStudent();
  const { t, i18n } = useTranslation()

  useEffect(() => {
    getDashboardHead();
  }, [localStorage.branch, t]);

  const getDashboardHead = async () => {
    if (localStorage.branch != undefined) {
      let result = await helperService.getDashboardHead(localStorage.branch);
      if (result.success) {
        setDashboard(result.data);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  return (
    <div className="ml-10 mr-10">
    <div className="row">
    <HeadCardItem header={t("dashboard.students")} theme={state.theme} count={dashboard?.studentCount} icon={"people_alt"} desc={t('dashboard.description')} url={`${alias}/student`} />
    <HeadCardItem header={t("dashboard.groups")} theme={state.theme} count={dashboard?.groupCount} icon={"groups"} desc={t('dashboard.description')} url={`${alias}/group`} />
      <HeadCardItem header={t("dashboard.payments")} theme={state.theme} count={dashboard?.paymentCount} icon={"paid"} desc={t('dashboard.description')} url={`${alias}/financeOperation/4`} />
      <HeadCardItem header={t("dashboard.contracts")} theme={state.theme} count={dashboard?.trainerContractCount} icon={"receipt_long"} desc={t('dashboard.description')}  url={`${alias}/contract/trainer`} />
    </div>
  </div>
  )
}
